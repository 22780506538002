import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_list = _resolveComponent("table-list")!

  return (_openBlock(), _createBlock(_component_table_list, {
    columns: _ctx.columns,
    apis: { list: _ctx.apiUrl },
    "is-upload": true,
    type: "image",
    "list-columns": _ctx.listColumns
  }, null, 8, ["columns", "apis", "list-columns"]))
}

import { defineComponent } from 'vue';

import TableList from '@/components/tables/TableList.vue';

const columns = [
  {
    title: 'File name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Photo',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
  },
  {
    title: 'Face',
    dataIndex: 'faces',
    key: 'faces',
  },
];

const listColumns = [{ title: '만든 사람', key: 'username' }];

export default defineComponent({
  name: 'UploadImage',
  components: { TableList },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      columns,
      listColumns,
    };
  },
});
